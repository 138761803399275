import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { Container, CircularProgress, Box, Typography, Chip, Accordion, AccordionSummary, AccordionDetails, Grid, Alert, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScoreIndicator from './ScoreIndicator';
import LoginPopup from './LoginPopup';

const BlurredText = ({ textLength, maxLength = 50 }) => {
    const length = Math.min(textLength, maxLength);
    return (
        <Box
            sx={{
                filter: 'blur(5px)',
                userSelect: 'none',
                color: 'transparent',
                textShadow: '0 0 8px rgba(0,0,0,0.5)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
        >
            <Typography>{'\u2588'.repeat(length)}</Typography>
        </Box>
    );
};

function ScanResults() {
    const location = useLocation();
    const { domain } = useParams();
    const [scanData, setScanData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [existingScanMessage, setExistingScanMessage] = useState('');
    const navigate = useNavigate();
    const [expandedSection, setExpandedSection] = useState(null);

    const handleReScan = useCallback(() => {
        navigate('/', { state: { reScan: true, website: domain } });
    }, [navigate, domain]);

    const handleAccordionChange = (section) => (event, isExpanded) => {
        setExpandedSection(isExpanded ? section : null);
    };

    useEffect(() => {
        const fetchScanResults = async () => {
            try {
                const response = await axios.get(`/scan-results/${domain}`);
                const parsedData = JSON.parse(response.data.scan_results);
                setScanData(parsedData);
                setLoading(false);

                const checkResponse = await axios.get(`/check-existing-scan/${domain}`);
                if (checkResponse.data.existingScan) {
                    const scanDate = new Date(checkResponse.data.scanDate);
                    const formattedDate = scanDate.toLocaleDateString();
                    setExistingScanMessage({
                        text: `This is an existing scan from ${formattedDate}.`,
                        scanDate: checkResponse.data.scanDate
                    });
                }
            } catch (err) {
                console.error('API Error:', err);
                setError(err.response?.data?.error || 'Failed to fetch scan results. Our server may be overloaded, Please refresh the page in 15 seconds.');
                setLoading(false);
            }
        };

        const verifyAuthToken = async () => {
            const token = localStorage.getItem('authToken');
            if (token) {
                try {
                    const response = await axios.get('/verify-token', {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (response.data.isValid) {
                        await checkUserStatus();
                    } else {
                        // Token is invalid, clear it and update state
                        localStorage.removeItem('authToken');
                        setIsLoggedIn(false);
                        setIsSubscribed(false);
                    }
                } catch (error) {
                    console.error('Error verifying token:', error);
                    localStorage.removeItem('authToken');
                    setIsLoggedIn(false);
                    setIsSubscribed(false);
                }
            } else {
                setIsLoggedIn(false);
                setIsSubscribed(false);
            }
        };

        verifyAuthToken();
        fetchScanResults();

        // Add this new effect to update the document title
        document.title = `EcomScore.ai - Scan Results - Ecommerce Site Scanner and Ranker`;

        // Optional: Restore the original title when component unmounts
        return () => {
            document.title = "EcomScore.ai - Ecommerce Site Scanner and Ranker";
        };
    }, [domain]);

    const renderLoginMessage = () => {
        if (!isLoggedIn) {
            console.log('Current path:', location.pathname);
            const redirectUrl = location.pathname + location.search; // Include search params
            localStorage.setItem('redirectUrl', redirectUrl);
            const redirectParam = `redirect=${encodeURIComponent(redirectUrl)}`;
            const loginPath = `/login?${redirectParam}`;
            const registerPath = `/register`;
            return (
                <Alert severity="info"
                        sx={{ 
                        my: 2,
                        '& .MuiAlert-message': {
                            width: '100%',
                            textAlign: 'center'
                    }
                }}>
                    <Typography sx={{ mb: 1 }}>
                        <Link to={registerPath}>Create a Free Account</Link> to view the results for each check below.
                    </Typography>
                </Alert>
            );
        }
        return null;
    };

    const renderExistingScanMessage = () => {
        if (!existingScanMessage) return null;

        if (isLoggedIn && isSubscribed) {
            return (
                <Alert
                    severity="info"
                    sx={{
                        my: 2,
                        '& .MuiAlert-message': {
                            width: '100%',
                            textAlign: 'center'
                        }
                    }}
                >
                    <Typography>{existingScanMessage.text}</Typography>
                    <Typography sx={{ mt: 1 }}>Did you want to run a new scan?</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1 }}
                        onClick={handleReScan}
                    >
                        Re-Scan
                    </Button>
                </Alert>
            );
        } else if (isLoggedIn && !isSubscribed) {
            return (
                <Alert
                    severity="info"
                    sx={{
                        my: 2,
                        '& .MuiAlert-message': {
                            width: '100%',
                            textAlign: 'center'
                        }
                    }}
                >
                    <Typography>Users without a subscription cannot re-scan a site. {existingScanMessage.text}</Typography>
                    <Typography sx={{ mt: 1 }}>
                        <Link to="/pricing">Upgrade</Link> to run on-demand scans
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1 }}
                        component={Link}
                        to="/pricing"
                    >
                        Upgrade
                    </Button>
                </Alert>
            );
        } else {
            return (
                <Alert
                    severity="info"
                    sx={{
                        my: 2,
                        '& .MuiAlert-message': {
                            width: '100%',
                            textAlign: 'center'
                        }
                    }}
                >
                    <Typography sx={{ fontStyle: 'italic' }}>
                        This is an existing scan from {new Date(existingScanMessage.scanDate).toLocaleDateString()}.
                    </Typography>
                </Alert>
            );
        }
    };

    const renderUpgradeMessage = useCallback(() => {
        const openCalendlyWindow = () => {
            const width = 600;
            const height = 700;
            const left = (window.screen.width / 2) - (width / 2);
            const top = (window.screen.height / 2) - (height / 2);

            window.open(
                'https://calendly.com/cybersolutions/ecomscore-ai-scan-results?hide_gdpr_banner=1',
                'Calendly',
                `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`
            );
        };

        return (
            <Box textAlign="center" py={2}>
                <Typography variant="h6" gutterBottom>
                    Upgrade Required
                </Typography>
                <Typography variant="body1" paragraph>
                    Please upgrade your account to view all details.<br/>
                    Don't want to upgrade? Schedule a call with us and we'll give you all the details.
                </Typography>
                <Box>
                    <Button
                        component={Link}
                        to="/pricing"
                        variant="contained"
                        color="primary"
                        sx={{ mr: 2 }}
                    >
                        Upgrade
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={openCalendlyWindow}
                    >
                        Schedule a Call
                    </Button>
                </Box>
            </Box>
        );
    }, []);

    const checkUserStatus = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                console.log('No auth token found in localStorage');
                setIsLoggedIn(false);
                setIsSubscribed(false);
                return;
            }

            const response = await axios.get('/user-status', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log('User status response:', response.data);

            if (response.data && typeof response.data.isLoggedIn === 'boolean') {
                setIsLoggedIn(response.data.isLoggedIn);
                setIsSubscribed(response.data.isSubscribed || false);
            } else {
                console.error('Unexpected response format from /user-status');
                setIsLoggedIn(false);
                setIsSubscribed(false);
            }
        } catch (error) {
            console.error('Error checking user status:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
            }
            setIsLoggedIn(false);
            setIsSubscribed(false);
        }
    };

    const getScoreInfo = (score, maxScore) => {
        const percentage = (score / maxScore) * 100;
        if (percentage < 55) return { label: 'Poor', color: '#E70033' };
        if (percentage < 66.25) return { label: 'Fair', color: '#FF7643' };
        if (percentage < 77.5) return { label: 'Good', color: '#EDB700' };
        if (percentage < 88.75) return { label: 'Very Good', color: '#7ED957' };
        return { label: 'Excellent', color: '#008037' };
    };

    const renderChecks = useMemo(() => {
        const getStatusColor = (status) => {
            switch (status.toLowerCase()) {
                case 'excellent':
                case 'good':
                case 'success':
                    return 'green';
                case 'fair':
                case 'warning':
                    return 'orange';
                case 'poor':
                case 'danger':
                case 'exposed':
                    return 'red';
                default:
                    return 'grey';
            }
        };

        const renderCheckDetails = (checkData) => {
            if (!isLoggedIn) {
                console.log("User not logged in. Current path:", location.pathname);
                return (
                    <Typography>
                        Please <Link to="/register">Create an Account</Link> to view the details.
                    </Typography>
                );
            }

            const isPositiveResult = ['good', 'success'].includes(checkData.status.toLowerCase());
            const shouldBlur = !isSubscribed && !isPositiveResult;

            if (shouldBlur) {
                return (
                    <>
                        <BlurredText textLength={checkData.message.length} maxLength={100} />
                        <Box mt={2} mb={2} p={1} bgcolor="#fffde7">
                            <Box display="flex" alignItems="center">
                                <InfoIcon style={{ marginRight: '8px', color: '#1976d2' }} />
                                <BlurredText textLength={checkData.info.length} maxLength={150} />
                            </Box>
                        </Box>
                        {renderUpgradeMessage()}
                    </>
                );
            }

            return (
                <>
                    <Typography variant="body1" gutterBottom>{checkData.message}</Typography>
                    <Box mt={2} mb={2} p={1} bgcolor="#fffde7">
                        <Box display="flex" alignItems="center">
                            <InfoIcon style={{ marginRight: '8px', color: '#1976d2' }} />
                            <Typography variant="body2">
                                {checkData.info}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant="subtitle2" gutterBottom>Detailed Results:</Typography>
                    <Box mt={1} p={1} bgcolor="#f5f5f5" overflow="auto">
                        <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontFamily: "'Courier New', Courier, monospace" }}>
                            {JSON.stringify(checkData.results, null, 2)}
                        </pre>
                    </Box>
                </>
            );
        };

        return (checks, sectionName) => {
            if (!checks || typeof checks !== 'object') {
                return <Typography>No checks available</Typography>;
            }

            return (
                <>
                    {Object.entries(checks).map(([checkName, checkData]) => {
                        const isPositiveResult = ['good', 'success'].includes(checkData.status.toLowerCase());
                        const shouldBlur = !isSubscribed && !isPositiveResult;
                        const formattedScore = Number(checkData.score).toFixed(1);

                        return (
                            <Accordion key={checkName}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label={`${checkName} details`}
                                >
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs>
                                            {shouldBlur ? (
                                                <BlurredText textLength={checkName.length} maxLength={30} />
                                            ) : (
                                                <Typography variant="subtitle1">
                                                    {checkName.replace(/_/g, ' ')}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                label={checkData.status}
                                                size="small"
                                                style={{
                                                    backgroundColor: getStatusColor(checkData.status),
                                                    color: 'white',
                                                    width: '80px',  // Fixed width for the Chip
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" noWrap style={{ minWidth: '80px', textAlign: 'right' }}>
                                                Score: {formattedScore}/10
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {isLoggedIn ? (
                                        shouldBlur ? renderUpgradeMessage() : renderCheckDetails(checkData)
                                    ) : (
                                        <LoginPopup />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </>
            );
        };
    }, [isLoggedIn, isSubscribed, location.pathname, renderUpgradeMessage]);

    const countStatusResults = (scanData) => {
        const counts = { danger: 0, warning: 0, poor: 0 };
        Object.values(scanData).forEach(section => {
            if (section.checks) {
                Object.values(section.checks).forEach(check => {
                    const status = check.status.toLowerCase();
                    if (status === 'danger' || status === 'exposed') counts.danger++;
                    else if (status === 'warning') counts.warning++;
                    else if (status === 'poor') counts.poor++;
                });
            }
        });
        return counts;
    };

    if (loading) {
        return (
            <Container maxWidth="md">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="md">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <Typography color="error">{error}</Typography>
                </Box>
            </Container>
        );
    }

    if (!scanData) {
        return (
            <Container maxWidth="md">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <Typography>No scan data available for {domain}</Typography>
                </Box>
            </Container>
        );
    }

    // New check for non-Magento sites
    if (scanData.message) {
        return (
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom align="center">
                        Scan Results for {domain}
                    </Typography>
                    <Alert severity="info">
                        <Typography variant="body1">{scanData.message}</Typography>
                    </Alert>
                    <Box mt={2}>
                        <Typography><strong>Detected Application:</strong> {scanData.detected_application}</Typography>
                        <Typography><strong>Scan Date:</strong> {scanData.scan_date}</Typography>
                    </Box>
                </Box>
            </Container>
        );
    }

    const totalScore = Object.values(scanData).reduce((acc, section) => {
        if (typeof section === 'object' && section.total_score) {
            return acc + section.total_score;
        }
        return acc;
    }, 0);

    const totalMaxScore = Object.values(scanData).reduce((acc, section) => {
        if (typeof section === 'object' && section.total_max_score) {
            return acc + section.total_max_score;
        }
        return acc;
    }, 0);

    const scoreInfo = getScoreInfo(totalScore, totalMaxScore);
    const statusCounts = countStatusResults(scanData);

    return (
        <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
                Scan Results for {domain}
            </Typography>
            {renderLoginMessage()}
            {renderExistingScanMessage()}
            <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                <Typography variant="h5" gutterBottom>Your Ecom Score</Typography>
                <ScoreIndicator
                    score={totalScore}
                    maxScore={totalMaxScore}
                    label={scoreInfo.label}
                    color={scoreInfo.color}
                />
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent="center" gap={1} mb={4}>
                <Chip
                    label={`Danger - Found ${statusCounts.danger} items`}
                    style={{ backgroundColor: 'rgb(231, 0, 51)', color: 'white', fontWeight: 'bold' }}
                />
                <Chip
                    label={`Poor - Found ${statusCounts.poor} items`}
                    style={{ backgroundColor: 'rgb(211, 47, 47)', color: 'white', fontWeight: 'bold' }}
                />
                <Chip
                    label={`Warning - Found ${statusCounts.warning} items`}
                    color="warning"
                    style={{ fontWeight: 'bold' }}
                />
            </Box>
            
            {/* New text added here */}
            <Typography variant="h6" align="center" gutterBottom sx={{ mt: 4, mb: 2 }}>
                Scan Details Below. Click Each Check to Expand and See Scan Details
            </Typography>

            {Object.entries(scanData).map(([section, data]) => {
                if (section === 'url' || !data || typeof data !== 'object') return null;

                const dangerCount = Object.values(data.checks || {}).filter(check => 
                    ['danger', 'exposed'].includes(check.status.toLowerCase())).length;
                const poorCount = Object.values(data.checks || {}).filter(check => 
                    check.status.toLowerCase() === 'poor').length;
                const warningCount = Object.values(data.checks || {}).filter(check => 
                    check.status.toLowerCase() === 'warning').length;

                return (
                    <Accordion 
                        key={section}
                        expanded={expandedSection === section}
                        onChange={handleAccordionChange(section)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs>
                                    <Typography variant="h6">
                                        {section.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} Check
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        {dangerCount > 0 && (
                                            <Typography variant="body2" mr={2} sx={{ color: 'rgb(231, 0, 51)', fontWeight: 'bold' }}>
                                                {dangerCount} Danger item{dangerCount !== 1 ? 's' : ''}
                                            </Typography>
                                        )}
                                        {poorCount > 0 && (
                                            <Typography variant="body2" mr={2} sx={{ color: 'rgb(211, 47, 47)', fontWeight: 'bold' }}>
                                                {poorCount} Poor item{poorCount !== 1 ? 's' : ''}
                                            </Typography>
                                        )}
                                        {warningCount > 0 && (
                                            <Typography variant="body2" sx={{ color: 'rgb(237, 108, 2)', fontWeight: 'bold' }}>
                                                {warningCount} Warning item{warningCount !== 1 ? 's' : ''}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {isLoggedIn ? (
                                data.checks ? renderChecks(data.checks, section) : <Typography>No checks available for this section</Typography>
                            ) : (
                                <LoginPopup />
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Container>
    );
}

export default ScanResults;