import axiosInstance from '../api/axios';
import axios from 'axios';

export const checkMaintenanceStatus = async () => {
  try {
    const clientIP = await getClientIP();
    const response = await axiosInstance.get('/maintenance-status', {
      params: { clientIP }
    });
    console.log('Maintenance status response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error checking maintenance status:', error);
    throw error;
  }
};

export const getClientIP = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching client IP:', error);
    return null;
  }
};

export const verifyToken = async (token) => {
  try {
    const response = await axiosInstance.get('/verify-token', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error verifying token:', error);
    throw error;
  }
};
