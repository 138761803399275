// src/components/ReCaptcha.js
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ReCaptcha = ({ children }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LdmzS4qAAAAAMCeQaaxY-6ZZx3nIWzoJHd70kIK">
            {children}
        </GoogleReCaptchaProvider>
    );
};

export default ReCaptcha;