import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function MaintenancePage() {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <img src="/maintenance.svg" alt="Maintenance" style={{ maxWidth: '100%', height: 'auto', marginBottom: '2rem' }} />
        <Typography variant="h4" component="h1" gutterBottom>
          We're currently updating the site
        </Typography>
        <Typography variant="body1">
          Please check back soon. We apologize for any inconvenience.
        </Typography>
      </Box>
    </Container>
  );
}

export default MaintenancePage;
