import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Typography, Button, Box, CircularProgress } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import axios from '../api/axios';
import { checkAuthStatus } from '../actions/authActions';

const CheckoutPage = () => {
    const { planName } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated, user } = useSelector(state => state.auth);
    const [sessionId, setSessionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        dispatch(checkAuthStatus());
    }, [dispatch]);

    useEffect(() => {
        setUserEmail(user?.email || localStorage.getItem('userEmail') || '');
    }, [user]);

    useEffect(() => {
        const fetchPublishableKey = async () => {
            try {
                const response = await axios.get(`/stripe-config`);
                const publishableKey = response.data.publishableKey;
                setStripePromise(loadStripe(publishableKey));
            } catch (error) {
                console.error('Error fetching Stripe publishable key:', error);
                setError('Failed to load Stripe. Please try again later.');
            }
        };

        fetchPublishableKey();
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login', { state: { from: `/checkout/${planName}` } });
        }
    }, [isAuthenticated, navigate, planName]);

    const handleCheckout = async () => {
        if (!stripePromise) {
            setError('Stripe has not been initialized. Please try again later.');
            return;
        }

        setLoading(true);
        try {
            if (!sessionId) {
                const response = await axios.post(`/create-checkout-session`, {
                    planName,
                    userEmail
                });

                if (!response.data.sessionId) {
                    throw new Error('No sessionId in response');
                }

                setSessionId(response.data.sessionId);
                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
                if (error) {
                    throw error;
                }
            } else {
                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({ sessionId });
                if (error) {
                    throw error;
                }
            }
        } catch (error) {
            console.error('Checkout error:', error);
            setError(error.message || 'Failed to proceed to checkout. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    console.log('Auth state:', { isAuthenticated, user, stripePromise, loading, userEmail });

    if (!isAuthenticated) {
        return (
            <Container maxWidth="sm">
                <CircularProgress />
            </Container>
        );
    }

    if (loading) {
        return (
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="sm">
                <Typography variant="h6" color="error" align="center">
                    {error}
                </Typography>
                <Button onClick={() => navigate('/dashboard')} variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    Return to Dashboard
                </Button>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" align="center" gutterBottom>
                Checkout for {planName} Plan
            </Typography>
            <Box my={4}>
                <Typography variant="h6" gutterBottom>
                    Plan Details:
                </Typography>
                <Typography>
                    • Access to all EcomScore features
                </Typography>
                <Typography>
                    • Unlimited scans
                </Typography>
                <Typography>
                    • 24/7 support
                </Typography>
            </Box>
            <Typography variant="h5" align="center" gutterBottom>
                Price: $9.99/month
            </Typography>
            <Box mt={4}>
                <Typography variant="body1" align="center" gutterBottom>
                    You will be charged using the email: {userEmail || 'Email not available'}
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleCheckout}
                    disabled={!stripePromise || loading || !userEmail}
                >
                    {loading ? 'Processing...' : 'Proceed to Payment'}
                </Button>
            </Box>
            <Box mt={2}>
                <Typography variant="body2" align="center">
                    By proceeding, you agree to our Terms of Service and Privacy Policy.
                </Typography>
            </Box>
        </Container>
    );
};

export default CheckoutPage;