import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { Container, Typography, Alert } from '@mui/material';

function VerifyEmail() {
    const [status, setStatus] = useState('verifying');
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                await axios.get(`/verify-email/${token}`);
                setStatus('success');
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            } catch (error) {
                setStatus('error');
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <Container maxWidth="xs">
            <Typography variant="h4">Email Verification</Typography>
            {status === 'verifying' && (
                <Alert severity="info">Verifying your email...</Alert>
            )}
            {status === 'success' && (
                <Alert severity="success">
                    Your email has been verified successfully. You will be redirected to the login page in 5 seconds.
                </Alert>
            )}
            {status === 'error' && (
                <Alert severity="error">
                    Failed to verify your email. The verification link may be invalid or expired.
                </Alert>
            )}
        </Container>
    );
}

export default VerifyEmail;