import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useSearchParams, Link } from 'react-router-dom';
import { TextField, Button, Container, Typography, Alert, Box } from '@mui/material';
import { loginUser, googleLogin } from '../actions/authActions';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { styled } from '@mui/material/styles';

const CustomGoogleButton = styled(Button)({
  height: '56px',
  width: '100%',
  marginTop: '16px',
  backgroundColor: '#fff',
  color: '#757575',
  border: '1px solid #dadce0',
  '&:hover': {
    backgroundColor: '#f8f9fa',
    border: '1px solid #dadce0',
  },
  textTransform: 'none',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '16px',
  paddingLeft: '8px', // Add some padding for the icon
});

const HiddenGoogleButton = styled('div')({
  display: 'none',
});

const LoginButton = styled(Button)({
  height: '56px',
});

const GoogleLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
    <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
    <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
    <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
    <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
  </svg>
);

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const googleButtonRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [redirectPath, setRedirectPath] = useState('/dashboard');

  useEffect(() => {
    const storedRedirectUrl = localStorage.getItem('redirectUrl');
    if (storedRedirectUrl) {
      setRedirectPath(storedRedirectUrl);
    }
    
    return () => {
      // Clear the redirectUrl from localStorage when component unmounts
      localStorage.removeItem('redirectUrl');
    };
  }, []);

  const handleGoogleResponse = useCallback(async (response) => {
    if (response.error) {
      console.error('Google Sign-In error:', response.error);
      setError('Google Sign-In failed. Please try again.');
      return;
    }

    try {
      await dispatch(googleLogin(response));
      console.log("Login successful. Redirecting to:", redirectPath);
      navigate(redirectPath);
    } catch (error) {
      setError('Google login failed. Please try again.');
      console.error('Google login error:', error);
    }
  }, [dispatch, navigate, redirectPath]);

  useEffect(() => {
    if (window.google && process.env.REACT_APP_GOOGLE_CLIENT_ID && googleButtonRef.current) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleResponse,
        ux_mode: 'popup',
        auto_select: false,
        cancel_on_tap_outside: true
      });
      
      window.google.accounts.id.renderButton(
        googleButtonRef.current,
        {
          theme: "outline",
          size: "large",
          type: "standard",
          shape: "rectangular",
          text: "signin_with",
          width: '100%',
          logo_alignment: "left"
        }
      );
    }
  }, [handleGoogleResponse]);

  const triggerGoogleSignIn = () => {
    if (googleButtonRef.current) {
      googleButtonRef.current.querySelector('div[role="button"]').click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setIsSubmitting(true);
    try {
      const token = await executeRecaptcha('login');
      const scannedDomain = localStorage.getItem('scannedDomain');
      await dispatch(loginUser({ email, password, recaptchaToken: token, scannedDomain }));
      console.log("Login successful. Redirecting to:", redirectPath);
      navigate(redirectPath);
    } catch (error) {
      if (error.response?.status === 403) {
        setMessage(error.response.data.error);
      } else {
        setError(error.response?.data?.error || 'An error occurred. Please try again later.');
      }
      console.error('Login failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4">Login</Typography>
      {message && <Alert severity="info" sx={{ mt: 2, mb: 2 }}>{message}</Alert>}
      {error && <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>}
      <CustomGoogleButton
        startIcon={<GoogleLogo />}
        onClick={triggerGoogleSignIn}
      >
        Sign in with Google
      </CustomGoogleButton>
      <HiddenGoogleButton ref={googleButtonRef} />
      <Box sx={{ my: 2, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1, height: '1px', backgroundColor: 'grey.300' }} />
        <Typography variant="body2" sx={{ mx: 2 }}>Or</Typography>
        <Box sx={{ flex: 1, height: '1px', backgroundColor: 'grey.300' }} />
      </Box>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
        <LoginButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Logging in...' : 'Login'}
        </LoginButton>
      </form>
      <Box mt={2}>
        <Link to="/forgot-password">Forgot password?</Link>
      </Box>
      <Box mt={2}>
        <Typography>
          Don't have an account? <Link to="/register">Register</Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default Login;