import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PricingPage = () => {
    const navigate = useNavigate();

    const plans = [
        { name: 'Beta', title: 'Bronze Plan (Beta)', price: 9.99, features: ['View all details of scan results', 'Unlimited Re-scans', 'Automated Monthly scan (Work in Progress)', 'Monthly Email w/ detected changes (Work in Progress)'] },
        /*{ name: 'Pro', price: 19.99, features: ['All Beta features', 'Feature 4', 'Feature 5'] },*/
    ];

    const handleSubscribe = (planName) => {
        navigate(`/checkout/${planName.toLowerCase()}`);
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h2" align="center" gutterBottom>
                Choose Your Plan
            </Typography>
            <Typography variant="h2" align="center" gutterBottom>
                Special beta pricing available for a limited time only.
            </Typography>
            <Grid container spacing={4}>
                {plans.map((plan) => (
                    <Grid item key={plan.name} xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4" align="center">
                                    {plan.title}
                                </Typography>
                                <Typography variant="h3" align="center">
                                    ${plan.price}/month
                                </Typography>
                                <ul>
                                    {plan.features.map((feature, index) => (
                                        <Typography key={index} component="li">{feature}</Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSubscribe(plan.name)}
                                >
                                    Subscribe
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default PricingPage;