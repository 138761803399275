import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const ScoreIndicator = ({ score, maxScore, label, color }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const radius = isMobile ? 90 : 120;
    const strokeWidth = isMobile ? 18 : 24;
  const normalizedRadius = radius - strokeWidth / 2;

  const colors = ['#E70033', '#FF7643', '#EDB700', '#7ED957', '#008037'];

  const getNeedleRotation = (score, maxScore) => {
    const percentage = (score / maxScore) * 100;
    // Adjust the needle position to match the arc sections
    if (percentage < 55) return (percentage / 55) * 36;
    if (percentage < 66.25) return 36 + ((percentage - 55) / (66.25 - 55)) * 36;
    if (percentage < 77.5) return 72 + ((percentage - 66.25) / (77.5 - 66.25)) * 36;
    if (percentage < 88.75) return 108 + ((percentage - 77.5) / (88.75 - 77.5)) * 36;
    return 144 + ((percentage - 88.75) / (100 - 88.75)) * 36;
  };

  return (
      <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <svg height={radius + 40} width={radius * 2} viewBox={`0 0 ${radius * 2} ${radius + 40}`}>
        <g transform={`rotate(180 ${radius} ${radius})`}>
          {colors.map((color, index) => {
            const startAngle = (index * 36) * (Math.PI / 180);
            const endAngle = ((index + 1) * 36) * (Math.PI / 180);
            const x1 = radius + normalizedRadius * Math.cos(startAngle);
            const y1 = radius + normalizedRadius * Math.sin(startAngle);
            const x2 = radius + normalizedRadius * Math.cos(endAngle);
            const y2 = radius + normalizedRadius * Math.sin(endAngle);

            return (
              <path
                key={color}
                d={`M ${x1},${y1} A ${normalizedRadius},${normalizedRadius} 0 0,1 ${x2},${y2}`}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
              />
            );
          })}

          <line
            x1={radius}
            y1={radius}
            x2={radius * 2 - strokeWidth - 10}
            y2={radius}
            stroke="black"
            strokeWidth="4"
            transform={`rotate(${getNeedleRotation(score, maxScore)} ${radius} ${radius})`}
          />
          <circle cx={radius} cy={radius} r="10" fill="black" />
        </g>
      </svg>
          <Typography variant={isMobile ? "h5" : "h4"} component="div" color="textSecondary">
              {Math.round(score)}
          </Typography>
          <Typography variant={isMobile ? "subtitle1" : "h6"} component="div" style={{ color: color, fontWeight: 'bold' }}>
              {label}
          </Typography>
          <Typography variant={isMobile ? "body2" : "body1"} component="div" color="textSecondary">
              Score: {Math.round(score)} out of {maxScore}
          </Typography>
    </Box>
  );
};

export default ScoreIndicator;
