import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CssBaseline, Container } from '@mui/material';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import ScanResults from './components/ScanResults';
import Login from './components/Login';
import Register from './components/Register';
import PricingPage from './components/PricingPage';
import CheckoutPage from './components/CheckoutPage';
import SuccessPage from './components/SuccessPage';
import VerifyEmail from './components/VerifyEmail';
import ReCaptcha from './components/ReCaptcha';
import SchedulingSuccess from './components/SchedulingSuccess';
import MaintenancePage from './components/MaintenancePage';
import { checkMaintenanceStatus } from './utils/api';

// Create a theme instance.
const theme = createTheme({
  // You can customize your theme here
  palette: {
    primary: {
      main: '#1976d2', // You can change this to your preferred primary color
    },
    secondary: {
      main: '#dc004e', // You can change this to your preferred secondary color
    },
  },
});

// Initialize Google Analytics
ReactGA.initialize('G-DCYWPJPY8L');

function App() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Initialize Mouseflow
    window._mfq = window._mfq || [];
    const mf = document.createElement("script");
    mf.type = "text/javascript";
    mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/02d52f84-b707-4e02-8d75-cdf1799d6ab3.js";
    document.getElementsByTagName("head")[0].appendChild(mf);

    const fetchMaintenanceStatus = async () => {
      try {
        const status = await checkMaintenanceStatus();
        setIsMaintenanceMode(status.maintenanceMode);
      } catch (error) {
        console.error('Error fetching maintenance status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMaintenanceStatus();

    // Track page views for Google Analytics
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

    // Update page title based on the current route
    const pageName = getPageName(location.pathname);
    document.title = `EcomScore.ai - ${pageName} - Ecommerce Site Scanner and Ranker`;

    // Add LinkedIn Insights tag
    window._linkedin_partner_id = "5814322";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

    const linkedInScript = document.createElement('script');
    linkedInScript.type = "text/javascript";
    linkedInScript.innerHTML = `
      (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `;
    document.body.appendChild(linkedInScript);

    // Add noscript tag for LinkedIn Insights
    const linkedInNoscript = document.createElement('noscript');
    const linkedInImg = document.createElement('img');
    linkedInImg.height = 1;
    linkedInImg.width = 1;
    linkedInImg.style.display = 'none';
    linkedInImg.alt = '';
    linkedInImg.src = "https://px.ads.linkedin.com/collect/?pid=5814322&fmt=gif";
    linkedInNoscript.appendChild(linkedInImg);
    document.body.appendChild(linkedInNoscript);

    // Add Facebook Pixel
    const fbScript = document.createElement('script');
    fbScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1025950722335885');
      fbq('track', 'PageView');
    `;
    document.body.appendChild(fbScript);

    // Add noscript tag for Facebook Pixel
    const fbNoscript = document.createElement('noscript');
    const fbImg = document.createElement('img');
    fbImg.height = 1;
    fbImg.width = 1;
    fbImg.style.display = 'none';
    fbImg.src = "https://www.facebook.com/tr?id=1025950722335885&ev=PageView&noscript=1";
    fbNoscript.appendChild(fbImg);
    document.body.appendChild(fbNoscript);

    // Cleanup function
    return () => {
      document.body.removeChild(linkedInScript);
      document.body.removeChild(linkedInNoscript);
      document.body.removeChild(fbScript);
      document.body.removeChild(fbNoscript);
      // Remove Mouseflow script if needed
      const mouseflowScript = document.querySelector('script[src^="//cdn.mouseflow.com/projects/"]');
      if (mouseflowScript) {
        mouseflowScript.remove();
      }
    };
  }, [location]);

  // Helper function to get page name from path
  const getPageName = (path) => {
    const routeMap = {
      '/': 'Dashboard',
      '/login': 'Login',
      '/register': 'Register',
      '/dashboard': 'Dashboard',
      '/pricing': 'Pricing',
      '/success': 'Success',
      '/scheduling-success': 'Scheduling Success',
    };

    // Handle dynamic routes
    if (path.startsWith('/scan-results/')) return 'Scan Results';
    if (path.startsWith('/checkout/')) return 'Checkout';
    if (path.startsWith('/verify-email/')) return 'Verify Email';

    return routeMap[path] || 'Page Not Found';
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner component
  }

  if (isMaintenanceMode) {
    return <MaintenancePage />;
  }

  return (
      <ReCaptcha>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Navigation />
          <Box component="main" flexGrow={1} mt={16} mb={4}>
            <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/scan-results/:domain" element={<ScanResults />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/checkout/:planName" element={<CheckoutPage />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                <Route path="/scheduling-success" element={<SchedulingSuccess />} />
              </Routes>
            </Container>
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
      </ReCaptcha>
  );
}

export default App;
