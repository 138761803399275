import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Typography,
  Container,
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Alert
} from '@mui/material';

function Dashboard() {
  const [website, setWebsite] = useState('');
  const [scanning, setScanning] = useState(false);
  const [scanProgress, setScanProgress] = useState(0);
  const [error, setError] = useState(null);
  const [scanId, setScanId] = useState(null);
  const [scanMessage, setScanMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [forceScan, setForceScan] = useState(false);
  const [isReScanTriggered, setIsReScanTriggered] = useState(false);
  const [normalizedDomain, setNormalizedDomain] = useState('');
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          setIsLoggedIn(false);
          setIsSubscribed(false);
          return;
        }

        const response = await axios.get('/app/user-status', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        setIsLoggedIn(response.data.isLoggedIn);
        setIsSubscribed(response.data.isSubscribed);
      } catch (error) {
        console.error('Error checking user status:', error);
        setIsLoggedIn(false);
        setIsSubscribed(false);
      }
    };

    checkUserStatus();
  }, [location]);

  const pollScanStatus = useCallback(async () => {
    if (!scanId) return;

    try {
      const response = await axios.get(`/app/scan-status/${scanId}`);
      const { status, progress, message } = response.data;

      console.log('Polling response:', response.data); // Debugging line

      setScanProgress(progress);
      setScanMessage(message || 'Scanning in progress...');

      if (status === 'completed') {
        setScanning(false);
        navigate(`/scan-results/${encodeURIComponent(normalizedDomain)}`);
      } else if (status === 'failed') {
        throw new Error('Scan failed');
      }
    } catch (error) {
      console.error('Polling error:', error);
      setError('Error checking scan status. Please try again.');
      setScanning(false);
    }
  }, [scanId, navigate, normalizedDomain]);

  useEffect(() => {
    let intervalId;
    if (scanning && scanId) {
      pollScanStatus(); // Immediately call once
      intervalId = setInterval(pollScanStatus, 5000); // Then poll every 5 seconds
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [scanning, scanId, pollScanStatus]);

  const handleScan = useCallback(async (e, isReScan = false) => {
    if (e) e.preventDefault();
    if (!website || scanning) {
      return;
    }

    // Re-check login status before proceeding
    const token = localStorage.getItem('authToken');
    if (!token || !isLoggedIn) {
      setShowLoginPrompt(true);
      return;
    }

    setScanning(true);
    setError(null);
    setScanProgress(0);

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not available');
      setError('ReCAPTCHA not available. Please try again later.');
      setScanning(false);
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha('scan');

      // Normalize the domain
      const normalizedDomain = website.replace(/^https?:\/\//i, '').replace(/^www\./i, '');
      setNormalizedDomain(normalizedDomain);

      // Save the normalized domain to localStorage
      localStorage.setItem('scannedDomain', normalizedDomain);

      // Get the auth token if it exists
      const authToken = localStorage.getItem('authToken');

      // Prepare headers
      const headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      };

      // Add Authorization header if authToken exists
      if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
      }

      const response = await axios.post('/app/start-scan',
          {
            url: normalizedDomain,
            isSubscribed,
            isLoggedIn,
            recaptchaToken,
            forceScan: isReScan || forceScan
          },
          {
            headers: headers,
            withCredentials: true,
          }
      );

      if (response.data.existingScan) {
        setScanning(false);
        navigate(`/scan-results/${encodeURIComponent(normalizedDomain)}`);
        return;
      }

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      setScanId(response.data.scanId);
      setScanProgress(0);
      setScanMessage('Initiating scan...');
    } catch (error) {
      console.error('Scan error:', error);
      setError(error.response?.data?.details || error.message || 'An unknown error occurred');
      setScanning(false);
    }
  }, [website, executeRecaptcha, forceScan, isSubscribed, isLoggedIn, navigate, scanning]);

  useEffect(() => {
    if (location.state && location.state.reScan && !isReScanTriggered) {
      const reScanUrl = location.state.website;
      setForceScan(true);
      setWebsite(reScanUrl);
      setIsReScanTriggered(true);
    }
  }, [location, isReScanTriggered]);  // Add isReScanTriggered to the dependency array

  useEffect(() => {
    if (isReScanTriggered && website && !scanning) {
      handleScan(null, true);
    }
  }, [isReScanTriggered, website, scanning, handleScan]);

  // Reset isReScanTriggered when leaving the page
  useEffect(() => {
    return () => {
      setIsReScanTriggered(false);
    };
  }, []);

  return (
      <Container maxWidth="md">
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <Typography variant="subtitle1" gutterBottom>
            Free Ecom Site Score
          </Typography>
          <Typography variant="h3" component="h1" gutterBottom>
            Uncover Issues in Your Ecommerce Site
          </Typography>
          <Typography variant="body1" paragraph>
            Use our free Ecommerce Site Scanner to scan your site for issues.
          </Typography>
          {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
          )}
          <Box component="form" onSubmit={handleScan} sx={{ mt: 3 }}>
            <TextField
                fullWidth
                label="Enter Website URL"
                variant="outlined"
                placeholder="www.yourwebsite.com"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                sx={{ mb: 2 }}
                disabled={scanning}
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={scanning}
            >
              {scanning ? 'Scanning...' : 'Free Scan'}
            </Button>
          </Box>
        </Box>
        <Dialog open={scanning}>
          <DialogContent>
            <DialogContentText>
              {scanMessage}
            </DialogContentText>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress variant="determinate" value={scanProgress} />
            </Box>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              {scanProgress}% complete
            </Typography>
          </DialogContent>
        </Dialog>
        {normalizedDomain && (
          <Typography variant="body1" paragraph>
            Scanning: {normalizedDomain}
          </Typography>
        )}
        <Dialog open={showLoginPrompt} onClose={() => setShowLoginPrompt(false)}>
          <DialogContent>
            <DialogContentText>
              You must be logged in to run a scan. Please{' '}
              <Link to="/login" onClick={() => setShowLoginPrompt(false)}>Login</Link> or{' '}
              <Link to="/register" onClick={() => setShowLoginPrompt(false)}>Register</Link>.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Container>
  );
}

export default Dashboard;