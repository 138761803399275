import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams, Link } from 'react-router-dom';
import { TextField, Button, Container, Typography, Alert, Box, Grid, Paper } from '@mui/material';
import { registerUser, googleRegister } from '../actions/authActions';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';

const CustomGoogleButton = styled(Button)({
  height: '56px',
  width: '100%',
  marginTop: '16px',
  backgroundColor: '#fff',
  color: '#757575',
  border: '1px solid #dadce0',
  '&:hover': {
    backgroundColor: '#f8f9fa',
    border: '1px solid #dadce0',
  },
  textTransform: 'none',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '16px',
  paddingLeft: '8px', // Add some padding for the icon
});

const GoogleLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
    <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
    <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
    <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
    <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
  </svg>
);

const HiddenGoogleButton = styled('div')({
  display: 'none',
});

const RegisterButton = styled(Button)({
  height: '56px',
});

const FullWidthContainer = styled(Container)({
  maxWidth: '100% !important',
  width: '100% !important',
  padding: '0 !important',
});

const FullWidthGrid = styled(Grid)({
  width: '100% !important',
  margin: '0 !important',
});

const FullWidthGridItem = styled(Grid)({
  width: '100% !important',
  padding: '0 16px !important', // Add some padding on the sides
});

const RightContainerTypography = styled(Typography)(({ theme }) => ({
  fontSize: `calc(${theme.typography.fontSize}px - 0.25em)`,
}));

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [scannedDomain, setScannedDomain] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const registrationMessage = useSelector(state => state.auth.registrationMessage);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    const [localMessage, setLocalMessage] = useState('');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get('redirect') || '/dashboard';
    const [redirectPathState, setRedirectPathState] = useState('/dashboard');
    const googleButtonRef = useRef(null);

    useEffect(() => {
        console.log('Register page state:', location.state);
        const domainFromStorage = localStorage.getItem('scannedDomain');
        setScannedDomain(domainFromStorage || '');

        const redirectFromStorage = localStorage.getItem('redirectUrl');
        const newRedirectPath = redirectFromStorage || '/dashboard';
        setRedirectPathState(newRedirectPath);

        return () => {
            dispatch({ type: 'CLEAR_REGISTRATION_MESSAGE' });
            localStorage.removeItem('redirectUrl');
        };
    }, [dispatch, location]);

    const handleGoogleResponse = useCallback(async (response) => {
        if (response.error) {
            console.error('Google Sign-In error:', response.error);
            setError('Google Sign-In failed. Please try again.');
            return;
        }

        try {
            await dispatch(googleRegister(response, scannedDomain, ''));
            setLocalMessage(`Registration successful! You will be redirected to ${redirectPathState.includes('/scan-results') ? 'the Scan Results' : 'the Dashboard'} in 5 seconds.`);
            
            const id = setTimeout(() => {
                navigate(redirectPathState, { replace: true });
            }, 5000);
            setTimeoutId(id);
        } catch (error) {
            console.error('Error in Google Sign-In callback:', error);
            setError('Google registration failed. Please try again.');
        }
    }, [dispatch, scannedDomain, navigate, redirectPathState]);

    useEffect(() => {
        if (window.google && process.env.REACT_APP_GOOGLE_CLIENT_ID && googleButtonRef.current) {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleGoogleResponse,
                ux_mode: 'popup',
                auto_select: false,
                cancel_on_tap_outside: true
            });
            
            window.google.accounts.id.renderButton(
                googleButtonRef.current,
                {
                    theme: "outline",
                    size: "large",
                    type: "standard",
                    shape: "rectangular",
                    text: "signup_with",
                    width: '100%',
                    logo_alignment: "left",
                }
            );
        }
    }, [handleGoogleResponse]);

    const triggerGoogleSignIn = () => {
        if (googleButtonRef.current) {
            googleButtonRef.current.querySelector('div[role="button"]').click();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        try {
            const token = await executeRecaptcha('register');
            await dispatch(registerUser({ email, password, recaptchaToken: token, scannedDomain }));
            setLocalMessage(
                `Registration successful! Account verificaton is required. Please check your email for the verification link. (It may be in your spam folder)`
            );
            setEmail('');
            setPassword('');
            setHasStartedTyping(false);
        } catch (error) {
            setError(error.response?.data?.error || 'Registration failed. Please try again.');
            console.error('Registration failed:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        if (!hasStartedTyping) {
            setHasStartedTyping(true);
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    return (
        <FullWidthContainer>
            <FullWidthGrid container spacing={4} sx={{ mt: 0, mb: 4 }}>
                <FullWidthGridItem item xs={12} lg={6}>
                    <Paper elevation={3} sx={{ p: 4, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ maxWidth: '500px', width: '100%', mx: 'auto' }}>
                            <Typography variant="h4" gutterBottom align="center">Create a Free Account</Typography>
                            {localMessage && (
                                <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
                                    {localMessage}
                                </Alert>
                            )}
                            {error && <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>}
                            <CustomGoogleButton
                                startIcon={<GoogleLogo />}
                                onClick={triggerGoogleSignIn}
                            >
                                Sign up with Google
                            </CustomGoogleButton>
                            <HiddenGoogleButton ref={googleButtonRef} />
                            <Box sx={{ my: 2, display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ flex: 1, height: '1px', backgroundColor: 'grey.300' }} />
                                <Typography variant="body2" sx={{ mx: 2 }}>Or</Typography>
                                <Box sx={{ flex: 1, height: '1px', backgroundColor: 'grey.300' }} />
                            </Box>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    error={hasStartedTyping && getPasswordStrength(password) === 'Weak'}
                                    helperText={
                                        hasStartedTyping ? getPasswordStrengthMessage(password) : ''
                                    }
                                    margin="normal"
                                />
                                <RegisterButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Registering...' : 'Register'}
                                </RegisterButton>
                            </form>
                            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                By registering, you agree to our{' '}
                                <Link to="/terms-of-service">Terms of Service</Link> and{' '}
                                <Link to="/privacy-policy">Privacy Policy</Link>.
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                Existing user? <Link to="/login">Log In</Link>
                            </Typography>
                        </Box>
                    </Paper>
                </FullWidthGridItem>
                <FullWidthGridItem item xs={12} lg={6}>
                    <Paper elevation={3} sx={{ p: 4, height: '100%' }}>
                        <RightContainerTypography variant="h5" gutterBottom><b>Create your free account to view the additional details of your scan!</b></RightContainerTypography>
                        <RightContainerTypography variant="body1" paragraph>
                            Ecommerce site issues could be losing you customers. Try our Ecommerce scanner and identify problems with your site.
                        </RightContainerTypography>
                        <RightContainerTypography variant="h6" gutterBottom sx={{ mt: 4 }}><b>Why EcomScore.ai?</b></RightContainerTypography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <StarIcon color="primary" />
                                <RightContainerTypography variant="body1">Built by ecommerce developers with over 17 years of ecommerce experience</RightContainerTypography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <StarIcon color="primary" />
                                <RightContainerTypography variant="body1">Combines traditional scanners and tools with AI for powerful results</RightContainerTypography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <StarIcon color="primary" />
                                <RightContainerTypography variant="body1">Allows you to resolve discovered issues on your own or w/ your own developers</RightContainerTypography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <StarIcon color="primary" />
                                <RightContainerTypography variant="body1">Many more exciting features being worked on</RightContainerTypography>
                            </Box>
                        </Box>
                        <RightContainerTypography variant="h6" gutterBottom sx={{ mt: 4 }}><b>Analyze, resolve, and outrank your competitors.</b></RightContainerTypography>
                        <ul>
                            <RightContainerTypography component="li">COMING SOON! Get more specific direction (tailored to your site) on how to resolve discovered issues</RightContainerTypography>
                            <RightContainerTypography component="li">COMING SOON! Advanced AI analysis of your Ecommerce efforts beyond a typical scanner.</RightContainerTypography>
                            <RightContainerTypography component="li">COMING SOON! Tools for your site Ecommerce - Site Down Notifier, Sitemap Validation Tool, Robots.txt Validator, SSL Certificate expiration alerts, Site Speed Heartbeat</RightContainerTypography>
                        </ul>
                        <RightContainerTypography variant="h6" sx={{ mt: 2 }}><b>Create your account now and start increasing your site rank now, before your competitors do.</b></RightContainerTypography>
                    </Paper>
                </FullWidthGridItem>
            </FullWidthGrid>
        </FullWidthContainer>
    );
}

// Helper functions for password strength
const getPasswordStrength = (password) => {
    const hasLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);

    if (!hasLength) {
        return 'Weak';
    }

    if (hasLength && hasLowerCase && hasUpperCase && hasNumber) {
        return 'Strong';
    }

    return 'Medium';
};

const getPasswordStrengthMessage = (password) => {
    const strength = getPasswordStrength(password);

    switch (strength) {
        case 'Weak':
            return 'Password should be at least 8 characters long and contain a mix of uppercase, lowercase, and numeric characters.';
        case 'Medium':
            return 'Password should contain a mix of uppercase, lowercase, and numeric characters.';
        case 'Strong':
            return 'Strong password.';
        default:
            return '';
    }
};

export default Register;