import axios from '../api/axios';
import { jwtDecode } from 'jwt-decode';

// Action Types
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

// Register User
export const registerUser = (userData) => async (dispatch) => {
  console.log('registerUser action called with:', userData);
  try {
    const response = await axios.post('/register', userData);
    console.log('Registration API response:', response.data);
    dispatch({
      type: 'REGISTER_SUCCESS',
      payload: response.data.message
    });
    return response.data;
  } catch (error) {
    console.error('Registration API error:', error.response ? error.response.data : error.message);
    console.error('Full error object:', error);
    dispatch({
      type: 'REGISTER_FAILURE',
      payload: error.response ? error.response.data : error.message
    });
    throw error;
  }
};

export const googleLogin = (credentialResponse) => async (dispatch) => {
  try {
    console.log('Credential response:', credentialResponse); // Log the credential response
    const response = await axios.post('/google-login', { credential: credentialResponse.credential });
    console.log('Backend response:', response.data); // Log the backend response

    const token = response.data.token;
    if (!token || typeof token !== 'string') {
      throw new Error('Invalid token received from server');
    }

    localStorage.setItem('authToken', token);
    setAuthToken(token);
    const decodedToken = jwtDecode(token);
    const user = {
      email: decodedToken.email,
      ...decodedToken
    };
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token, user }
    });
    return token;
  } catch (error) {
    console.error('Google login error:', error.response ? error.response.data : error.message);
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response ? error.response.data : error.message
    });
    throw error;
  }
};

export const googleRegister = (credentialResponse, scannedDomain) => async (dispatch) => {
  try {
    const response = await axios.post('/google-register', {
      credential: credentialResponse.credential,
      scannedDomain
    });
    const { token } = response.data; // Assuming the backend returns a 'token' property

    if (!token || typeof token !== 'string') {
      throw new Error('Invalid token received from server');
    }

    localStorage.setItem('authToken', token);
    setAuthToken(token);
    const decodedToken = jwtDecode(token);
    const user = {
      email: decodedToken.email,
      ...decodedToken
    };

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token, user }
    });

    return token;
  } catch (error) {
    dispatch({
      type: REGISTER_FAILURE,
      payload: error.response ? error.response.data : error.message
    });
    throw error;
  }
};

// Login User
export const loginUser = ({ email, password, recaptchaToken, scannedDomain }) => async (dispatch) => {
  try {
    const response = await axios.post('/login', { email, password, recaptchaToken, scannedDomain });
    const token = response.data.token;
    localStorage.setItem('authToken', token);
    setAuthToken(token);
    const decodedToken = jwtDecode(token);
    const user = {
      email: email,
      ...decodedToken
    };
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token, user }
    });
    return token;
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response ? error.response.data : error.message
    });
    throw error;
  }
};

// Logout User
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('authToken');
  setAuthToken(null);
  dispatch({ type: LOGOUT });
};

// Check Auth Status
export const checkAuthStatus = () => (dispatch) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    setAuthToken(token);
    const decodedToken = jwtDecode(token);
    const user = {
      ...decodedToken,
      email: decodedToken.email || ''
    };
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token, user }
    });
  } else {
    dispatch({ type: LOGOUT });
  }
};

// Set auth token
export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};