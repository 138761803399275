// src/components/Footer.js
import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                p: 2,
                textAlign: 'center'
            }}
        >
            <Typography variant="body2">
                Made with ❤ by{' '}
                <Link
                    href="https://cybersolutions-llc.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'white' }}
                >
                    CyberSolutions
                </Link>
            </Typography>
        </Box>
    );
};

export default Footer;