import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box, Button } from '@mui/material';
import axios from 'axios';

const SuccessPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const verifySubscription = async () => {
            const params = new URLSearchParams(location.search);
            const sessionId = params.get('session_id');
            if (!sessionId) {
                setError('No session ID found.');
                setLoading(false);
                return;
            }
            try {
                const response = await axios.post(`/app/verify-subscription`, { sessionId });
                setSubscription(response.data.subscription);
                setLoading(false);
            } catch (err) {
                console.error('Error verifying subscription:', err);
                setError('Failed to verify subscription. Please contact support.');
                setLoading(false);
            }
        };
        verifySubscription();
    }, [location]);

    if (loading) {
        return (
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="sm">
                <Typography variant="h6" color="error" align="center" gutterBottom>
                    {error}
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button component={Link} to="/dashboard" variant="contained" color="primary">
                        Return to Dashboard
                    </Button>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Box my={4}>
                <Typography variant="h4" align="center" gutterBottom>
                    Subscription Confirmed!
                </Typography>
                <Typography variant="body1" align="center">
                    Thank you for subscribing to EcomScore. Your {subscription?.plan || 'new'} plan is now active.
                </Typography>
                <Typography variant="body1" align="center" mt={2}>
                    You can now enjoy unlimited scans and all the features of your subscription.
                </Typography>
                <Box display="flex" justifyContent="center" mt={4}>
                    <Button component={Link} to="/dashboard" variant="contained" color="primary">
                        Go to Dashboard
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default SuccessPage;