import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { logoutUser, checkAuthStatus } from '../actions/authActions';

function Navigation() {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkAuthStatus());
    }, [dispatch]);

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <AppBar position="fixed">
            <Toolbar>
                <Typography variant="h6" component={RouterLink} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}>
                    EcomScore
                </Typography>
                <Box>
                    <Button color="inherit" component={RouterLink} to="/dashboard">New Scan</Button>
                    {/* Scan Results link is temporarily hidden */}
                    {/* <Button color="inherit" component={RouterLink} to="/scan-results">Scan Results</Button> */}
                    {isAuthenticated ? (
                        <Button color="inherit" onClick={handleLogout}>Logout</Button>
                    ) : (
                        <>
                            <Button color="inherit" component={RouterLink} to="/login">Login</Button>
                            <Button color="inherit" component={RouterLink} to="/register">Register</Button>
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Navigation;