import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';

function LoginPopup() {
    const location = useLocation();

    return (
        <Box textAlign="center" py={2}>
            <Typography variant="h6" gutterBottom>
                Login Required
            </Typography>
            <Typography variant="body1" paragraph>
                Please create an account or login to view detailed scan results.
            </Typography>
            <Box>
                <Button
                    component={Link}
                    to="/register"
                    variant="outlined"
                    color="primary"
                >
                    Create a Free Account
                </Button>
            </Box>
        </Box>
    );
}

export default LoginPopup;
