import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../api/axios';

function SchedulingSuccess() {
    const [status, setStatus] = useState('Processing...');
    const [eventDetails, setEventDetails] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const location = useLocation();

    useEffect(() => {
        console.log('URL Search Params:', location.search); // Debugging line

        const searchParams = new URLSearchParams(location.search);
        const calendlyEmail = searchParams.get('invitee_email');
        const eventName = searchParams.get('event_name');
        const eventStartTime = searchParams.get('event_start_time');
        const eventEndTime = searchParams.get('event_end_time');

        console.log('Parsed Params:', { calendlyEmail, eventName, eventStartTime, eventEndTime }); // Debugging line

        // Check if Calendly passed data through window.opener
        if (window.opener && window.opener.Calendly) {
            console.log('Calendly Data:', window.opener.Calendly.event);
            const calendlyData = window.opener.Calendly.event;
            setEventDetails({
                calendlyEmail: calendlyData.invitee.email || 'Not provided',
                eventName: calendlyData.event_type.name || 'Not provided',
                eventStartTime: new Date(calendlyData.start_time).toLocaleString() || 'Not provided',
                eventEndTime: new Date(calendlyData.end_time).toLocaleString() || 'Not provided'
            });
        } else if (calendlyEmail || eventName || eventStartTime || eventEndTime) {
            // If we have any data from URL params, use that
            setEventDetails({
                calendlyEmail: calendlyEmail || 'Not provided',
                eventName: eventName || 'Not provided',
                eventStartTime: eventStartTime ? new Date(eventStartTime).toLocaleString() : 'Not provided',
                eventEndTime: eventEndTime ? new Date(eventEndTime).toLocaleString() : 'Not provided'
            });
        } else {
            console.log('No event details available');
        }

        checkUserStatus();
    }, [location]);

    const checkUserStatus = async () => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                setStatus('Error: User not logged in');
                return;
            }

            const response = await axiosInstance.get('/user-status', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log('User Status Response:', response.data); // Debugging line

            if (response.data && response.data.isLoggedIn && response.data.email) {
                setUserEmail(response.data.email);
                updateMailchimpTag(response.data.email);
            } else {
                setStatus('Error: Unable to retrieve user information');
            }
        } catch (error) {
            console.error('Error checking user status:', error);
            setStatus('Error: Unable to verify user status');
        }
    };

    const updateMailchimpTag = async (email) => {
        try {
            const response = await axiosInstance.post('/update-mailchimp-tag', { email, tag: 'CALLSCHEDULED' });
            console.log('Mailchimp Update Response:', response.data); // Debugging line
            if (response.data.message === 'Tag updated successfully') {
                setStatus('Thank you for scheduling a call!');
            } else {
                setStatus('The call was scheduled, but there was an issue updating your Mailchimp tag.');
            }
        } catch (error) {
            console.error('Error updating Mailchimp tag:', error);
            setStatus('The call was scheduled, but there was an error updating your Mailchimp tag.');
        }
    };

    return (
        <div>
            <h1>Scheduling Status</h1>
            <p>{status}</p>
            {eventDetails && (
                <div>
                    <h2>Your Appointment Details:</h2>
                    <p>Event: {eventDetails.eventName}</p>
                    <p>Start Time: {eventDetails.eventStartTime}</p>
                    <p>End Time: {eventDetails.eventEndTime}</p>
                    <p>Email: {eventDetails.calendlyEmail}</p>
                </div>
            )}
        </div>
    );
}

export default SchedulingSuccess;
